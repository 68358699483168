<template>
  <!-- Begin page content -->
  <main class="">
    <div class="container">
      <div class="result">{{ text }}</div>
      <div ref="videoContainer" class="video-container">
        <video id="video" width="300" height="200"></video>
      </div>
    </div>
  </main>
  <!-- Begin page content -->
</template>

<script>
export default {
  name: "AppContent",
  data() {
    return {
      deviceIdList: [],
      text: ""
    }
  },
  methods: {
    initScanner() {
      let rect = this.$refs.videoContainer.getBoundingClientRect();
      this._video = document.getElementById("video");
      this._video.setAttribute('height', rect.height);
      this._video.setAttribute('width', rect.width);

      this._codeReader = this.codeReader || new window.ZXing.BrowserMultiFormatReader()

      this._codeReader.listVideoInputDevices()
        .then((deviceList) => {
          if (deviceList.length >= 1) {
            this.deviceIdList = deviceList.map((item) => {
              return {
                id: item.deviceId,
                name: item.label
              }
            });
            this.$parent.devIndex = 0;
            this.start();
          }

        })
        .catch((err) => {
          console.error(err)
        })
    },
    start() {
      if (!this._codeReader || this.$parent.devIndex < 0) return;
      this._codeReader.decodeFromVideoDevice(this.deviceIdList[this.$parent.devIndex].id, 'video', (result, err) => {
        if (result) {
          console.log(result)
          this.text = result.text
        }
        if (err && !(err instanceof window.ZXing.NotFoundException)) {
          console.error(err)
          this.text = err
        }
      })
      this.$parent.running = true;
      console.log('started')
    },
    stop() {
      if (!this._codeReader || this.$parent.devIndex < 0) return;
      this._codeReader.reset()
      this.$parent.running = false;
      console.log('stopped')
    },
    nextDevice() {
      if (this.$parent.devIndex < 0) return;
      if (this.$parent.devIndex == this.deviceIdList.length - 1) {
        this.$parent.devIndex = 0;
      }
      else {
        this.$parent.devIndex += 1;
      }
      this.stop();
      this.$nextTick(() => {
        this.start();
      })
    },
    toggle() {
      if (!this._codeReader || this.$parent.devIndex < 0) return;
      if (!this.$parent.running) this.start(); else this.stop();
    }
  },
  mounted() {
    this.initScanner();
  }
}
</script>

<style scoped>
main {
  flex-grow: 1;
  margin-top: 60px;
}
main > .container {
  position: relative;
  height: 100%;
}

main > .container > div.video-container {
  background-color: black;
  border: 1px solid gray;
  border-radius: 10px;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  z-index: 0;
}

main > .container > div.result {
  background-color: rgb(0, 0, 0, 0.6);
  color: white;
  font-size: 2em;
  position: absolute;
  bottom: 1px;
  width: 90%;
  margin: 0 5%;
  text-align: center;
  z-index: 1;
}
</style>