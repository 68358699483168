<template>
  <section class="d-flex flex-column h-100">
    <AppHeader />
    <AppContent ref="app_content" />
    <AppFooter
      @btn1="$refs.app_content && $refs.app_content.nextDevice()"
      @btn2="$refs.app_content && $refs.app_content.toggle()"
    />
  </section>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppContent from "@/components/AppContent.vue";
import AppFooter from "@/components/AppFooter.vue";
export default {
  name: 'AppBody',
  components: {
    AppHeader,
    AppContent,
    AppFooter
  },
  data() {
    return {
      devIndex: -1,
      running: false,
      text: ""
    }
  },
}
</script>

