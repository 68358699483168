<template>
  <!-- Begin page footer -->
  <footer class="footer mt-auto">
    <div class="container text-center">
      <div class="d-flex justify-content-between">
        <button
          class="btn px-2 mb-4 rounded-pill"
          type="button"
          @click="$emit('btn1')"
        >
          <i class="bi bi-camera"></i> {{ $parent.devIndex }}
        </button>
        <button
          class="btn px-2 mb-4 rounded-pill"
          type="button"
          @click="$emit('btn2')"
        >
          <i class="bi bi-stop-circle text-danger" v-if="$parent.running"></i>
          <i class="bi bi-play-circle text-info" v-else></i>
        </button>
        <button
          class="btn px-2 mb-4 rounded-pill"
          type="button"
          @click="$emit('btn3')"
        >
          <i class="bi bi-plugin"></i>
        </button>
      </div>
    </div>
  </footer>
  <!-- Begin page footer -->
</template>

<script>
export default {
  name: "AppFooter"

}
</script>

<style scoped>
</style>